export const shopify = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'orders', 'nextShippingDate', 'bundles'],
};

export const skubana = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'ownSkuLeadTime', 'orders', 'purchaseOrders', 'bundles'],
};

export const googleAnalytics = {
  supports: ['analytics'],
};

export const googleSheets = {
  supports: ['export'],
};

export const flexe = {
  supports: ['basics', 'skus', 'inventoryQuantities'],
};

export const anvyl = {
  supports: ['skus', 'purchaseOrders', 'ownSkuLeadTime'],
};

export const cin7 = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'purchaseOrders', 'orders', 'transfers'],
};

export const shipbob = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'orders', 'bundles'],
};

export const amazon = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'orders', 'transfers'],
};

export const fulfil = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'purchaseOrders', 'orders', 'bundles', 'transfers'],
};

export const linnworks = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'purchaseOrders', 'orders', 'bundles', 'transfers'],
};

export const shiphero = {
  supports: ['basics', 'skus', 'inventoryQuantities', 'purchaseOrders', 'orders', 'transfers'],
};

export const smartrr = {
  supports: ['subscriptions'],
};

export const csv = {
  supports: ['skus', 'inventoryQuantities', 'purchaseOrders', 'orders', 'bundles'],
};

export const sources = {
  shopify,
  skubana,
  googleAnalytics,
  googleSheets,
  flexe,
  anvyl,
  cin7,
  shipbob,
  amazon,
  fulfil,
  linnworks,
  shiphero,
  smartrr,
  csv,
};

export const hasSupportFor = (sourceType, what) => sources[sourceType]?.supports.includes(what);

export default {
  sources,
  hasSupportFor,
};
