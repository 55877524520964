import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Range } from 'react-range';
import { PLANS } from '../../shared/billing/plans.js';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import AccountSwitcher from '../Layout/AccountSwitcher.jsx';
import { compactNumber } from '../Planning/PlannedProducts/Index.jsx';
import BillingWidget from './BillingWidget.jsx';

const cogsyLogo = '/cogsy-logo-on-white.svg';

const availablePlans = ['growth', 'scale'];
const combinedPlanTiers = [];

availablePlans.forEach((plan) => {
  PLANS[plan].tiers.forEach((tier) => {
    const existingTierIndex = combinedPlanTiers.findIndex(
      (existingTier) => existingTier.revenueTo === tier.revenueTo,
    );

    if (existingTierIndex === -1) {
      combinedPlanTiers.push({ revenueTo: tier.revenueTo, [plan]: tier.price });
    } else {
      combinedPlanTiers[existingTierIndex] = {
        ...combinedPlanTiers[existingTierIndex],
        [plan]: tier.price,
      };
    }
  });
});

const REVENUE_SLIDER = {
  step: 1,
  min: 0,
  max: combinedPlanTiers.length - 1,
  default: 0,
};

const CREATE_CHECKOUT_URL = gql`
  mutation CreateBillingCheckoutUrl($plan: BillingPlan!, $revenueTo: Int!) {
    createBillingCheckoutUrl(plan: $plan, revenueTo: $revenueTo) {
      checkoutUrl
    }
  }
`;

const BillingPlans = () => {
  const { addAlert } = useAlerts();

  const [createBillingCheckoutUrl] = useMutation(CREATE_CHECKOUT_URL);

  const [planTiersIndex, setPlanTiersIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleCreateRecurringCharge = (plan) => {
    setLoading(true);

    const doCreate = async () => {
      try {
        const { data } = await createBillingCheckoutUrl({
          variables: { plan, revenueTo: combinedPlanTiers[planTiersIndex].revenueTo },
        });
        window.location.assign(data.createBillingCheckoutUrl.checkoutUrl);
      } catch (error) {
        addAlert('An error has occurred! Please refresh the page and try again.', { error });
        setLoading(false);
      }
    };

    doCreate();
  };

  return (
    <main className="mx-auto mt-12 flex flex-col items-center pb-20 text-midnight-75">
      <div>
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>
      <h1 className="mt-12 text-3xl font-medium text-midnight-100">
        Let’s choose the right Cogsy for you 😎
      </h1>

      <div className="mt-7 flex w-full max-w-md flex-col items-center text-base font-bold">
        <div className="flex items-center">
          What is your annual revenue?
          <Tooltip className="ml-1">
            <Tooltip.Element />
            <Tooltip.Body align="bottom">
              Calculated as the trailing 12 months (past 12 consecutive months)
            </Tooltip.Body>
          </Tooltip>
        </div>

        <div className="mt-5 w-full">
          <Range
            values={[planTiersIndex]}
            onChange={([value]) => setPlanTiersIndex(value)}
            {...REVENUE_SLIDER}
            renderTrack={({ props, children }) => (
              <div className="pb-12 pt-2">
                <div
                  {...props}
                  style={{
                    background: '#e2d2ff',
                  }}
                  className="h-1.5 rounded shadow-sm"
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="flex h-5 w-5 justify-center rounded-full bg-purple-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2"
              >
                <div className="absolute -bottom-10 flex justify-center whitespace-nowrap rounded bg-purple-100 px-2 py-1 text-xs tracking-[0.02rem] text-white shadow">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2 border-8 border-b-[12px] border-transparent border-b-purple-100" />
                  $
                  {planTiersIndex > 0
                    ? compactNumber(combinedPlanTiers[planTiersIndex - 1].revenueTo * 1000)
                    : 0}
                  &nbsp;– ${compactNumber(combinedPlanTiers[planTiersIndex].revenueTo * 1000)}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="mt-10 flex justify-center space-x-10">
        <BillingWidget
          title="Growth"
          description="The essentials"
          price={combinedPlanTiers[planTiersIndex].growth}
          revenueLimit={compactNumber(combinedPlanTiers[planTiersIndex].revenueTo * 1000)}
          onClick={() => handleCreateRecurringCharge('growth')}
          loading={loading}
          isDisabled={
            combinedPlanTiers[planTiersIndex].revenueTo >
            PLANS.growth.tiers[PLANS.growth.tiers.length - 1].revenueTo
          }
        />
        <BillingWidget
          hasAllOptions
          title="Scale"
          description="Everything"
          price={combinedPlanTiers[planTiersIndex].scale || PLANS.scale.tiers[0].price}
          revenueLimit={compactNumber(
            combinedPlanTiers[planTiersIndex].revenueTo > PLANS.scale.tiers[0].revenueTo
              ? combinedPlanTiers[planTiersIndex].revenueTo * 1000
              : PLANS.scale.tiers[0].revenueTo * 1000,
          )}
          onClick={() => handleCreateRecurringCharge('scale')}
          loading={loading}
        />
      </div>

      <div className="mt-8 max-w-md text-center text-xs font-medium text-gray-100">
        Credit card required. Monthly fee will be taken at the end of your free trial. If you cancel
        within your trial period you won’t pay anything.
      </div>

      <div className="max-w-md">
        <AccountSwitcher className="my-8 flex flex-wrap place-content-center text-center" />
      </div>
    </main>
  );
};

export default BillingPlans;
