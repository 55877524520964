import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FaGoogleDrive, FaShopify } from 'react-icons/fa';
import { FiBarChart, FiInfo, FiPlus, FiRefreshCw } from 'react-icons/fi';
import { Popover } from '@headlessui/react';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import EmptyTable from '../Components/EmptyTable.jsx';
import { shopify as shopifyConfig, skubana as skubanaConfig } from '../config.js';
import { useDocumentTitle } from '../Hooks/index.js';
import {
  AmazonIcon,
  AnvylIcon,
  Cin7Icon,
  ExtensivIcon,
  FlexeIcon,
  FulfilIcon,
  LinnworksIcon,
  ShipbobIcon,
  ShipheroIcon,
  SmartrrIcon,
} from '../Icons/index.js';
import Source from './Source.jsx';
import AmazonPopup from './Sources/AmazonPopup.jsx';
import AnvylPopup from './Sources/AnvylPopup.jsx';
import Cin7Popup from './Sources/Cin7Popup.jsx';
import FlexePopup from './Sources/FlexePopup.jsx';
import FulfilPopup from './Sources/FulfilPopup.jsx';
import GoogleAnalyticsPopup from './Sources/GoogleAnalyticsPopup.jsx';
import GoogleSheetsPopup from './Sources/GoogleSheetsPopup.jsx';
import LinnworksPopup from './Sources/LinnworksPopup.jsx';
import LocatePopup from './Sources/LocatePopup.jsx';
import ShipheroPopup from './Sources/ShipheroPopup.jsx';
import SmartrrPopup from './Sources/SmartrrPopup.jsx';

const CREATE_SHIPBOB_REDIRECT_URL = gql`
  mutation {
    createShipbobRedirectUrl {
      redirectUrl
    }
  }
`;

const TRIGGER_SYNC = gql`
  mutation TriggerSync {
    triggerSync
  }
`;

const Sources = () => {
  const { account, user } = useAccount();
  const { addAlert } = useAlerts();
  useDocumentTitle('Integrations Settings');

  const [googleAnalyticsPopupOpen, setGoogleAnalyticsPopupOpen] = React.useState(false);
  const [googleSheetsPopupOpen, setGoogleSheetsPopupOpen] = React.useState(false);
  const [locatePopupOpen, setLocatePopupOpen] = React.useState(false);
  const [flexePopupOpen, setFlexePopupOpen] = React.useState(false);
  const [anvylPopupOpen, setAnvylPopupOpen] = React.useState(false);
  const [cin7PopupOpen, setCin7PopupOpen] = React.useState(false);
  const [amazonPopupOpen, setAmazonPopupOpen] = React.useState(false);
  const [fulfilPopupOpen, setFulfilPopupOpen] = React.useState(false);
  const [linnworksPopupOpen, setLinnworksPopupOpen] = React.useState(false);
  const [shipheroPopupOpen, setShipheroPopupOpen] = React.useState(false);
  const [smartrrPopupOpen, setSmartrrPopupOpen] = React.useState(false);

  const [createShipbobRedirectUrl] = useMutation(CREATE_SHIPBOB_REDIRECT_URL);
  const [triggerSync] = useMutation(TRIGGER_SYNC);

  const toggleLocatePopupOpen = React.useCallback(() => {
    setLocatePopupOpen(!locatePopupOpen);
  }, [locatePopupOpen]);

  const toggleFlexePopupOpen = React.useCallback(() => {
    setFlexePopupOpen(!flexePopupOpen);
  }, [flexePopupOpen]);

  const toggleAnvylPopupOpen = React.useCallback(() => {
    setAnvylPopupOpen(!anvylPopupOpen);
  }, [anvylPopupOpen]);

  const toggleCin7PopupOpen = React.useCallback(() => {
    setCin7PopupOpen(!cin7PopupOpen);
  }, [cin7PopupOpen]);

  const toggleAmazonPopupOpen = React.useCallback(() => {
    setAmazonPopupOpen(!amazonPopupOpen);
  }, [amazonPopupOpen]);

  const toggleFulfilPopupOpen = React.useCallback(() => {
    setFulfilPopupOpen(!fulfilPopupOpen);
  }, [fulfilPopupOpen]);

  const toggleLinnworksPopupOpen = React.useCallback(() => {
    setLinnworksPopupOpen(!linnworksPopupOpen);
  }, [linnworksPopupOpen]);

  const toggleShipheroPopupOpen = React.useCallback(() => {
    setShipheroPopupOpen(!shipheroPopupOpen);
  }, [shipheroPopupOpen]);

  const toggleSmartrrPopupOpen = React.useCallback(() => {
    setSmartrrPopupOpen(!smartrrPopupOpen);
  }, [smartrrPopupOpen]);

  const handleShipbobConnect = React.useCallback(() => {
    const doConnect = async () => {
      const {
        data: {
          createShipbobRedirectUrl: { redirectUrl },
        },
      } = await createShipbobRedirectUrl();

      window.location.assign(redirectUrl);
    };

    doConnect();
  }, []);

  const triggerSyncAll = async () => {
    await triggerSync();
    addAlert('The sync has been triggered in the background, this may take some time...', {
      level: 'success',
    });
  };

  return (
    <div className="col-span-9 self-start text-midnight-100">
      <h5 className="text-xl">Integrations</h5>
      <div className="flex justify-between">
        <Popover className="relative mt-2">
          <Popover.Button className="mt-2 inline-flex items-center rounded-full bg-purple-100 px-3 py-2 text-xs font-bold text-white hover:bg-purple-90 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2 active:bg-purple-110">
            <FiPlus className="mr-1" size={16} />
            Add integration
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-4 w-56 overflow-hidden rounded bg-white shadow">
            <div className="flex flex-col text-xs">
              <div className="bg-gray-50 px-4 py-2 font-bold">Stores</div>

              {/* Amazon */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleAmazonPopupOpen}
              >
                <AmazonIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Amazon</span>
              </button>

              {/* Shopify */}
              <a
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                href={shopifyConfig.appUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaShopify size={14} className="text-midnight-75 group-hover:text-white" />
                <span className="ml-2">Shopify</span>
              </a>

              <div className="bg-gray-50 px-4 py-2 font-bold">Inventory Management</div>

              {/* Cin7 */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleCin7PopupOpen}
              >
                <Cin7Icon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Cin7 Omni</span>
              </button>

              {/* Extensiv Order Manager */}
              <a
                role="button"
                href={skubanaConfig.appUrl}
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
              >
                <ExtensivIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Extensiv Order Manager</span>
              </a>

              {/* Fulfil */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleFulfilPopupOpen}
              >
                <FulfilIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Fulfil</span>
              </button>

              {/* Linnworks */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleLinnworksPopupOpen}
              >
                <LinnworksIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Linnworks</span>
              </button>

              <div className="bg-gray-50 px-4 py-2 font-bold">Fulfilment &amp; Logistics</div>

              {/* Anvyl */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleAnvylPopupOpen}
              >
                <AnvylIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Anvyl</span>
              </button>

              {/* Flexe */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleFlexePopupOpen}
              >
                <FlexeIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Flexe</span>
              </button>

              {/* Shipbob */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={handleShipbobConnect}
              >
                <ShipbobIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Shipbob</span>
              </button>

              {/* Shiphero */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleShipheroPopupOpen}
              >
                <ShipheroIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Shiphero</span>
              </button>

              <div className="bg-gray-50 px-4 py-2 font-bold">Subscriptions</div>

              {/* Smartrr */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={toggleSmartrrPopupOpen}
              >
                <SmartrrIcon className="fill-midnight-75 group-hover:fill-white group-hover:text-white" />
                <span className="ml-2">Smartrr</span>
              </button>

              <div className="bg-gray-50 px-4 py-2 font-bold">Data Analysis</div>

              {/* Google Analytics */}
              <button
                type="button"
                className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                onClick={() => setGoogleAnalyticsPopupOpen(true)}
              >
                <FiBarChart size={14} className="text-midnight-75 group-hover:text-white" />
                <span className="ml-2">Google Analytics</span>
              </button>

              {/* Google Sheets */}
              <IfFeatureEnabled feature="google-sheets">
                <button
                  type="button"
                  className="group flex items-center px-4 py-2 hover:bg-purple-100 hover:font-bold hover:text-white"
                  onClick={() => setGoogleSheetsPopupOpen(true)}
                >
                  <FaGoogleDrive size={14} className="text-midnight-75 group-hover:text-white" />
                  <span className="ml-2">Google Sheets (export only)</span>
                </button>
              </IfFeatureEnabled>
            </div>
          </Popover.Panel>
        </Popover>
        {!user.isReadOnly && account.sources?.length > 0 && (
          <button
            type="button"
            className="mt-4 inline-flex items-center rounded-full border-2 border-purple-100 bg-transparent px-3 py-1 text-xs font-bold text-purple-100 hover:border-purple-90 hover:bg-purple-90 hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-75 disabled:bg-gray-75 disabled:text-gray-100"
            onClick={triggerSyncAll}
            disabled={account.isSyncInProgress}
            title={account.isSyncInProgress ? 'A sync is already running in the background' : ''}
          >
            <FiRefreshCw className="mr-1" size={16} />
            Sync all data
          </button>
        )}
      </div>

      {!account.sources || account.sources.length === 0 ? (
        <div className="mt-4">
          <EmptyTable title="Integrations" bodyText="No integrations to show at this time" />
        </div>
      ) : (
        <>
          <p className="mt-8 flex items-center">
            <FiInfo size={20} className="stroke-gray-100" />
            <span className="ml-2 text-xs">
              Toggle each switch to allow syncing that item. Cogsy syncs data from integrations each
              day. <br />
              Toggled items will sync at the next daily run.
              {account.sources?.length > 0 && (
                <> You can also manually trigger a sync with the button above.</>
              )}
            </span>
          </p>
          <div className="mt-12 grid grid-cols-2 gap-8 2xl:grid-cols-3">
            {account.sources?.map((source) => (
              <Source key={source.sourceId} source={source} />
            ))}
          </div>
        </>
      )}

      <GoogleAnalyticsPopup
        open={googleAnalyticsPopupOpen}
        onClose={() => setGoogleAnalyticsPopupOpen(false)}
      />
      <GoogleSheetsPopup
        open={googleSheetsPopupOpen}
        onClose={() => setGoogleSheetsPopupOpen(false)}
      />
      <FlexePopup open={flexePopupOpen} onClose={toggleFlexePopupOpen} />
      <LocatePopup open={locatePopupOpen} onClose={toggleLocatePopupOpen} />
      <AnvylPopup open={anvylPopupOpen} onClose={toggleAnvylPopupOpen} />
      <Cin7Popup open={cin7PopupOpen} onClose={toggleCin7PopupOpen} />
      <AmazonPopup open={amazonPopupOpen} onClose={toggleAmazonPopupOpen} />
      <FulfilPopup open={fulfilPopupOpen} onClose={toggleFulfilPopupOpen} />
      <LinnworksPopup open={linnworksPopupOpen} onClose={toggleLinnworksPopupOpen} />
      <ShipheroPopup open={shipheroPopupOpen} onClose={toggleShipheroPopupOpen} />
      <SmartrrPopup open={smartrrPopupOpen} onClose={toggleSmartrrPopupOpen} />
    </div>
  );
};

export default Sources;
